import React from 'react';
import { useParams } from 'react-router-dom';
import { blogs } from './BlogData';
import './css/BlogItem.css'

const BlogItem = () => {
  const { id } = useParams();
  const blog = blogs.find(blog => blog.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className='blogItem-container'>
      <h1 className='blog-title'>{blog.title}</h1>
      <p className='blog-content'>{blog.content}</p>
    </div>
  );
};

export default BlogItem;
