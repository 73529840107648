export const selfDevelopment10 = {
    id: 10,
    title: "个人成长第十弹：布施、奉献，你付出的将百倍回馈给你",
    content: 
    <div>
        <h2>1.利他能给你带来快乐</h2>
        <p>利他行为，会给人带来愉悦的感受。</p>
        <p>很多都是举手之劳，比如给别人指路，路上有石子把它移到一边，公共场合不要外放手机打扰别人，徒步的时候给别人一瓶多余的水。</p>
        <p>这些行为会给别人很大的帮助，给别人一点好处，而自己并不会有什么损失。不要损人而利己，甚至不利己，纯粹给别人添堵，这样会反噬在你身上。</p>
        <p>你希望下次遇到困境的时候能有陌生人伸出援手，将心比心，你就需要去帮助别人。而如果以给别人添堵为乐，下次陷入困境了谁来帮你？</p>

        <h2>2.商业成功的背后是利他</h2>
        <p>很多人做生意能成功，是因为他们随时都在准备利他，他们心里装着别人。</p>
        <p>对于消费者，他们会充分考虑消费者的需求，分析什么才是消费者真正需要的，从来生产出真正符合人们需求的产品。</p>
        <p>对于合作伙伴，他们会考虑你的需要，和你建立良好的关系，这样合作起来顺风顺水。</p>
        <p>人都是要互惠才有可能建立良好的关系和信誉的，一定要心里有别人，才能做成生意。</p>

        <h2>3.与人相处，要考虑他人感受</h2>
        <p>这点不言而喻，在一段关系里，如果只考虑自己，对方单方面付出，没几天别人就跑掉了，不会再和你做朋友。</p>
        <p>一定是相互付出，才能形成长久的关系。</p>

        <h2>4.布施得福报</h2>
        <p>以上3点都是看得见、摸得着的观点，在现实生活中不言而喻，偏于唯物的层面。背后的道理是，你要付出，才会有回报，心中有别人，才能建立良好的合作关系，自己也有收获。</p>
        <p>布施得福报，有点偏玄学，但是从上述道理推导，也还是相符的。</p>
        <p>打个比方，你帮助了一个人，别人可能会给予你回报，甚至建立长久合作关系。</p>
        <p>从福报的角度，你做了善事，就是有了善业，未来这种善业会给你带来正的回馈。就像存在银行的钱一样，你存的越多，到你要取钱的时候，就能取出来更多。有些人很辛苦也挣不到钱，有些人很轻松就挣到大钱了，道理就在于此。</p>
    </div>
  }


