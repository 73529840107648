const languageNameEn = {
  aar: 'Afar',
  abk: 'Abkhazian',
  afr: 'Afrikaans',
  aka: 'Akan',
  amh: 'Amharic',
  ara: 'Arabic',
  asm: 'Assamese',
  aym: 'Aymara',
  aze: 'Azerbaijani',
  bak: 'Bashkir',
  bam: 'Bambara',
  bel: 'Belarusian',
  ben: 'Bengali',
  bis: 'Bislama',
  bod: 'Tibetan',
  bos: 'Bosnian',
  bre: 'Breton',
  bul: 'Bulgarian',
  cat: 'Catalan; Valencian',
  ces: 'Czech',
  cha: 'Chamorro',
  che: 'Chechen',
  chu: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  chv: 'Chuvash',
  cor: 'Cornish',
  cos: 'Corsican',
  cre: 'Cree',
  cym: 'Welsh',
  dan: 'Danish',
  deu: 'German',
  div: 'Divehi; Dhivehi; Maldivian',
  dzo: 'Dzongkha',
  ell: 'Greek, Modern (1453-)',
  eng: 'English',
  epo: 'Esperanto',
  est: 'Estonian',
  ewe: 'Ewe',
  fao: 'Faroese',
  fas: 'Persian',
  fij: 'Fijian',
  fin: 'Finnish',
  fra: 'French',
  fry: 'Western Frisian',
  ful: 'Fulah',
  gla: 'Gaelic; Scottish Gaelic',
  gle: 'Irish',
  glg: 'Galician',
  glv: 'Manx',
  grn: 'Guarani',
  guj: 'Gujarati',
  hau: 'Hausa',
  heb: 'Hebrew',
  her: 'Herero',
  hin: 'Hindi',
  hmo: 'Hiri Motu',
  hrv: 'Croatian',
  hun: 'Hungarian',
  ibo: 'Igbo',
  ind: 'Indonesian',
  isl: 'Icelandic',
  ita: 'Italian',
  jav: 'Javanese',
  jpn: 'Japanese',
  kal: 'Kalaallisut; Greenlandic',
  kan: 'Kannada',
  kas: 'Kashmiri',
  kat: 'Georgian',
  kau: 'Kanuri',
  kaz: 'Kazakh',
  khm: 'Central Khmer',
  kik: 'Kikuyu; Gikuyu',
  kin: 'Kinyarwanda',
  kir: 'Kirghiz; Kyrgyz',
  kom: 'Komi',
  kon: 'Kongo',
  kor: 'Korean',
  kua: 'Kuanyama; Kwanyama',
  kur: 'Kurdish',
  lao: 'Lao',
  lat: 'Latin',
  lav: 'Latvian',
  lin: 'Lingala',
  lit: 'Lithuanian',
  ltz: 'Luxembourgish; Letzeburgesch',
  lub: 'Luba-Katanga',
  lug: 'Ganda',
  mah: 'Marshallese',
  mal: 'Malayalam',
  mar: 'Marathi',
  mkd: 'Macedonian',
  mlg: 'Malagasy',
  mlt: 'Maltese',
  mon: 'Mongolian',
  mri: 'Maori',
  msa: 'Malay',
  mya: 'Burmese',
  nau: 'Nauru',
  nav: 'Navajo; Navaho',
  nbl: 'Ndebele, South; South Ndebele',
  nde: 'Ndebele, North; North Ndebele',
  ndo: 'Ndonga',
  nep: 'Nepali',
  nld: 'Dutch; Flemish',
  nno: 'Norwegian Nynorsk; Nynorsk, Norwegian',
  nob: 'Bokmål, Norwegian; Norwegian Bokmål',
  nor: 'Norwegian',
  nya: 'Chichewa; Chewa; Nyanja',
  oci: 'Occitan (post 1500); Provençal',
  oji: 'Ojibwa',
  ori: 'Oriya',
  orm: 'Oromo',
  oss: 'Ossetian; Ossetic',
  pan: 'Panjabi; Punjabi',
  pli: 'Pali',
  pol: 'Polish',
  por: 'Portuguese',
  pus: 'Pushto; Pashto',
  que: 'Quechua',
  roh: 'Romansh',
  ron: 'Romanian; Moldavian; Moldovan',
  run: 'Rundi',
  rus: 'Russian',
  sag: 'Sango',
  san: 'Sanskrit',
  sin: 'Sinhala; Sinhalese',
  slk: 'Slovak',
  slv: 'Slovenian',
  sme: 'Northern Sami',
  smo: 'Samoan',
  sna: 'Shona',
  snd: 'Sindhi',
  som: 'Somali',
  sot: 'Sotho, Southern',
  spa: 'Spanish; Castilian',
  srd: 'Sardinian',
  srp: 'Serbian',
  ssw: 'Swati',
  sun: 'Sundanese',
  swa: 'Swahili',
  swe: 'Swedish',
  tah: 'Tahitian',
  tam: 'Tamil',
  tat: 'Tatar',
  tel: 'Telugu',
  tet: 'Tetum',
  tgk: 'Tajik',
  tgl: 'Tagalog',
  tha: 'Thai',
  tir: 'Tigrinya',
  ton: 'Tonga (Tonga Islands)',
  tsn: 'Tswana',
  tso: 'Tsonga',
  tuk: 'Turkmen',
  tur: 'Turkish',
  twi: 'Twi',
  uig: 'Uighur; Uyghur',
  ukr: 'Ukrainian',
  urd: 'Urdu',
  uzb: 'Uzbek',
  ven: 'Venda',
  vie: 'Vietnamese',
  vol: 'Volapük',
  wln: 'Walloon',
  wol: 'Wolof',
  xho: 'Xhosa',
  yid: 'Yiddish',
  yor: 'Yoruba',
  zha: 'Zhuang; Chuang',
  zho: 'Chinese',
  zul: 'Zulu',
  cmn: 'Chinese'
  };

  export default languageNameEn