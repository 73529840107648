import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>

      <p>Welcome to my website. I am committed to protecting your privacy. This Privacy Policy explains how I collect, use, and safeguard your personal information.</p>

      <h2>1. Information I Collect</h2>
      <p>I may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> When you register on my site, subscribe to my newsletter, or interact with me, you may provide personal information such as your name, email address, and phone number.</li>
        <li><strong>Usage Data:</strong> I may automatically collect information about how you access and use my site, including your IP address, browser type, access times, and pages you have visited.</li>
        <li><strong>Cookies and Similar Technologies:</strong> I use cookies and similar technologies to collect and store information to improve your user experience. You can disable cookies through your browser settings, but this may affect your ability to use my site.</li>
      </ul>

      <h2>2. How I Use Your Information</h2>
      <p>I use your information to:</p>
      <ul>
        <li>Provide and maintain my services.</li>
        <li>Communicate with you, including sending newsletters, responding to your requests, and providing customer support.</li>
        <li>Analyze and improve my site and services to enhance user experience.</li>
        <li>Comply with legal requirements and prevent fraud.</li>
      </ul>

      <h2>3. How I Share Your Information</h2>
      <p>I do not sell your personal information to third parties, but I may share it in the following situations:</p>
      <ul>
        <li><strong>Service Providers:</strong> I may share your information with third-party service providers (e.g., data analytics, payment processing) who perform services on my behalf, but only to the extent necessary to provide those services.</li>
        <li><strong>Legal Requirements:</strong> I may disclose your information to comply with legal obligations or to protect the rights, property, or safety of my site, users, or the public.</li>
      </ul>

      <h2>4. How I Protect Your Information</h2>
      <p>I implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of internet transmission or electronic storage is completely secure, and I cannot guarantee absolute security.</p>

      <h2>5. Your Rights</h2>
      <p>You may have rights under applicable laws to:</p>
      <ul>
        <li>Access and update your personal information.</li>
        <li>Request deletion of your personal information.</li>
        <li>Restrict or object to the processing of your personal information.</li>
      </ul>
      <p>To exercise these rights, please contact me.</p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>I may update this Privacy Policy from time to time. I will post any changes on this page and update the effective date of the policy. Please review this page periodically for the latest information.</p>

      <h2>7. Contact Me</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact me.</p>
    </div>
  );
};

export default PrivacyPolicy;
