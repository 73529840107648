import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2 className="footer-title">About Me</h2>
          <p className='about-content'>A solo developer. I provide useful online tools and high quality blogs.</p>
        </div>
        <div className="footer-section links">
          <h2 className="footer-title">Quick Links</h2>
          <ul>
            <NavLink to="/privacy-policy" className="footer-section-link">Privacy Policy</NavLink>
            <br/>
            <NavLink to="/terms-of-service" className="footer-section-link">Terms of Service</NavLink>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2 className="footer-title">Contact me</h2>
          <p>Email: <a href="mailto:sysuwws@gmail.com">sysuwws@gmail.com</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-bottom-p">&copy; {new Date().getFullYear()} poetsolodev.com. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
