export const selfDevelopment6 = {
    id: 6,
    title: "个人成长第六弹：学会培养福报，开源节流",
    content: 
    <div>
      <p>王德峰说过，一个人到了四十岁还不信命，此人悟性太差。</p>
      <p>穷通富贵，这辈子能挣多少钱，能享多少福，莫非前定。</p>
      <p>有些人辛辛苦苦一辈子，每天起早摸黑，却还是挣不到钱。也有人生在罗马，什么都不用做就衣食无忧。</p>
      <p>如果你不尊重命运，硬是想靠着辛勤努力去获得超出命定的财富，结果却会是不如人意，要不然就是你获得了钱，却马上又有灾难发生，让你拿不住这个钱。</p>
      <p>努力只是缘，最根本还是要从因上下手。打个比方，因是种子，缘是土壤，你光耕耘，却不播种，又谈何收获呢？</p>
      <p>这个因便是福报，有多大的福报，就能挣多少钱。因此最重要的是要去培养福报，福报深厚，钱就会追着你跑。</p>
      <p>以下是培养福报的具体方法：</p>

      <h2>1.布施</h2>
      <p>财布施得福报，在日常生活里，我们应该去帮助穷人、病人，现在网络众筹平台很多，可以通过这些平台给生病、受灾的人捐款。</p>
      <p>供养佛法僧三宝所得的福报更高，可以为需要帮助的寺庙捐献生活物资，为佛像贴金等等，哪怕是简单地到佛前上柱香、献个花也可以。最主要是要持之以恒，坚持供养三宝，福报自然会增长</p>

      <h2>2.持戒</h2>
      <p>在家人有五戒，杀生、偷盗、邪淫、妄语、饮酒。持戒精严，也有福报，能防止漏福报。首要第一条就是不能杀生，不止限于人类，动物也不要随意去杀。如果你身心因果，就会懂得每个生命都是轮回投胎来的，冤冤相报何时了？当你要享福的时候，这些冤亲债主可不会同意，让会要你破点财，甚至偿命。因此我们尊重所有的生灵，不要随意剥夺别人的生命。</p>

      <h2>3.念佛、念咒</h2>
      <p>常见的修福报的方法是念地藏王菩萨，从《地藏经》可以得知，每日念菩萨名千遍，至于千日，当得菩萨遣所在土地鬼神，保佑你终生衣食无忧。网上有很多修地藏法获得感应的例子，念地藏菩萨可以增长福报。</p>
      <p>咒语我推荐准提咒，读过《了凡四训》便知道，念准提咒，配合布施、悔过，就可以改命，获得比命定更多的财富和福报。</p>
      <p>准提法门网上也有很多感应实录: <a href="http://www.dazhouxian.com/news/?3344.html">《一位千万级準提行者的回忆录》</a></p>
      

      <h2>4.念经</h2>
      <p>念《地藏经》可以培养福报，像经里面讲的，在十斋日念一遍地藏经，可以消除业障，增长福报。</p>
      <p>当然念其他佛经，比如《金刚经》《心经》《华严经》等，也能有感应，因为背后都有佛菩萨加持，感应真实不虚。</p>
      <p>口说无凭，可以先试着去念一念，念一段时间之后，看看现实生活有没有变化。感受过佛菩萨的加持，你便会深信不疑了。</p>
      <p>坚持精进，最终就能获得不可思议的福报。</p>
    </div>
  } 
  
  
  
  
  
  
  
  
 
  
  
  
  
 
  



