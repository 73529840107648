import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './navbar/Navbar';
import Home from './home-page/Home';
import Blog from './blog/Blog';
import SoloProduct from './soloproduct/SoloProduct';
import Chinese from './chinese/Chinese';
import BlogDetail from './blog/BlogDetail';
import LanguageDetector from './language-detector/LanguageDetector';
import Footer from './footer/Footer';
import './App.css';
import PrivacyPolicy from './footer/PrivacyPolicy';
import TermsOfService from './footer/TermsOfService';
import SupportMe from './support-me/Support-me';
import DiZangPuSa from './buddhism/diZangPuSa/DiZangPuSa'
import Guanyinpusa from './buddhism/guanyinpusa/Guanyinpusa';
import NotFound from './navbar/NotFound';
import Amituofo from './buddhism/amituofo/Amituofo'
import Yaoshifo from './buddhism/yaoshifo/Yaoshifo'
import Zhuntizhou from './buddhism/zhuntizhou/Zhuntizhou'
import ScrollToTop from './ScrollToTop'
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-P89ZG8WM8S'; // 替换为你的跟踪 ID
ReactGA.initialize(TRACKING_ID);

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <PageTracker />
      <div>
       <Navbar />

        <div className='main-content'>
        
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/solo-product" element={<SoloProduct />} />
            <Route path="/chinese" element={<Chinese />} />
            <Route path='/solo-product/language-detector' element={<LanguageDetector/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path='/terms-of-service' element={<TermsOfService/>}/>
            <Route path='/support-me' element={<SupportMe/>}/>
            <Route path='/buddhism/dizangpusa' element={<DiZangPuSa/>}/>
            <Route path='/buddhism/guanyinpusa' element={<Guanyinpusa/>}/>
            <Route path='/buddhism/amituofo' element={<Amituofo/>}/>
            <Route path='/buddhism/yaoshifo' element={<Yaoshifo/>}/>
            <Route path='/buddhism/zhuntizhou' element={<Zhuntizhou/>}/>

            {/* 404 页面 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        

        <Footer/>
      </div>
    </Router>
  );
}


export default App;
