import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>

      <p>Welcome to my website. By accessing or using my website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By using my website, you agree to these Terms of Service. If you do not agree with these terms, please do not use my website.</p>

      <h2>2. Changes to Terms</h2>
      <p>I may update these Terms of Service from time to time. I will post any changes on this page and update the effective date. It is your responsibility to review these terms periodically for any changes.</p>

      <h2>3. Use of the Website</h2>
      <p>You agree to use my website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website.</p>

      <h2>4. Intellectual Property</h2>
      <p>The content and materials on my website, including but not limited to text, graphics, logos, and images, are protected by copyright and trademark laws. You may not reproduce, distribute, or otherwise use any content from my website without my express written permission.</p>

      <h2>5. User Content</h2>
      <p>You are solely responsible for any content you post on my website. By posting content, you grant me a worldwide, royalty-free, non-exclusive license to use, display, and distribute such content on my website.</p>

      <h2>6. Links to Third-Party Websites</h2>
      <p>My website may contain links to third-party websites that are not owned or controlled by me. I am not responsible for the content or practices of any third-party sites. You access such sites at your own risk.</p>

      <h2>7. Disclaimer of Warranties</h2>
      <p>My website is provided "as is" without any warranties, express or implied. I do not guarantee the accuracy, completeness, or reliability of any content on my website or that the website will be available at all times.</p>

      <h2>8. Limitation of Liability</h2>
      <p>In no event shall I be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of my website or these Terms of Service.</p>

      <h2>9. Governing Law</h2>
      <p>These Terms of Service shall be governed by and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law principles.</p>

      <h2>10. Contact Me</h2>
      <p>If you have any questions about these Terms of Service, please contact me.</p>
    </div>
  );
};

export default TermsOfService;
