import React from 'react';
import styles from './AmitabhaIntro.module.css';

function AmitabhaIntro() {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>阿彌陀佛簡介</h1>

            <p className={styles.text}>
                <strong>1. 阿彌陀佛：</strong> 阿彌陀佛，全稱為「南無阿彌陀佛」，是佛教淨土宗的核心人物之一，代表著無量的光明和壽命。
                阿彌陀佛發下了四十八大願，誓願救度一切眾生，尤其是願意帶領念佛的人往生西方極樂世界。阿彌陀佛的形象通常手持蓮花，象徵著淨土中潔淨無染的蓮花池。
                無論何時何地，只要心中誠心念誦阿彌陀佛的名號，就能得到佛力加持，獲得無邊的福德和智慧。
            </p>

            <p className={styles.text}>
                <strong>2. 西方極樂世界：</strong> 西方極樂世界，又稱為「安樂國」或「極樂淨土」，是阿彌陀佛的淨土。在這個世界中，沒有輪迴的痛苦與煩惱，眾生皆處於平等、安樂的狀態。
                據佛經描述，極樂世界的環境十分美麗，有七重行樹、七重羅網，遍布光彩絢麗的寶石池，清淨的水流淙淙，蓮花芬芳。在極樂世界裡，眾生無需經歷生老病死的苦難，只需專心修行，最終成佛。
            </p>

            <p className={styles.text}>
                <strong>3. 念阿彌陀佛的功德：</strong> 念阿彌陀佛（念佛）是淨土宗的主要修行法門，念佛的功德無量無邊。
                通過不斷念誦佛號，修行者可以淨化自己的心靈，遠離世俗的煩惱和惡業，積累無盡的福德。念佛能夠消除過去的罪業，增長現世的善根，乃至為未來世積聚善業。
                念佛還能增強人的定力與專注，使修行者更加平靜、自在地面對生活中的苦難和挑戰。最重要的是，念佛能夠帶領修行者在臨終時被阿彌陀佛接引，往生西方極樂世界，永遠離開輪迴之苦。
            </p>

            <p className={styles.text}>
                <strong>4. 淨土五經：</strong> 淨土宗的教義主要依托五部經典，這五部經典合稱為「淨土五經」，包括《無量壽經》、《觀無量壽經》、《阿彌陀經》、《大勢至菩薩念佛圓通章》和《普賢行願品》。
                <ul className={styles.list}>
                    <li><strong>《無量壽經》：</strong> 描述阿彌陀佛發下的四十八大願，以及極樂世界的美好。</li>
                    <li><strong>《觀無量壽經》：</strong> 通過觀想修行淨土的教導。</li>
                    <li><strong>《阿彌陀經》：</strong> 介紹念佛法門的殊勝功德。</li>
                    <li><strong>《大勢至菩薩念佛圓通章》：</strong> 講述大勢至菩薩通過念佛獲得圓滿成就的事蹟。</li>
                    <li><strong>《普賢行願品》：</strong> 結合念佛與普賢菩薩十大願王的修行方法。</li>
                </ul>
            </p>

            <p className={styles.text}>
                <strong>5. 念佛計數：</strong> 念佛計數是一種非常重要的念佛修行方式，幫助修行者在日常生活中保持專注與恆心。通過使用佛珠或計數器，修行者可以記錄自己每日念佛的次數，鼓勵持續的修行，並逐步增加念佛的數量。
                修行者不僅可以為自己計數，還可以為他人迴向功德，將念佛功德分享給他人，使得周圍的人也能獲得平安和幸福。計數的過程不僅是數量的積累，更是心靈淨化與信仰堅定的歷程，幫助修行者與阿彌陀佛建立深厚的感應。
            </p>
        </div>
    );
}

export default AmitabhaIntro;
