import React from 'react';
import './Chinese.css';

const Chinese = () => {
  return (
    <section className='chinese'>
      <h1>中文</h1>
      <p>欢迎来到中文页面。</p>
    </section>
  );
};

export default Chinese;
