export const selfDevelopment3 = {
    id: 3,
    title: "个人成长第三弹：学好英语",
    content: 
    <div>
      <p>从小到大学了十几年英语，仅仅是为了应付考试，真到了要讲英语的时候，就会脑袋空白、结结巴巴，对方讲的英语一句也听不懂。</p>
        <p>这种现象在英语新手身上非常普遍，犹记得第一次在外企工作，跟国外同事开会，要用英文自我介绍，由于是远程开会，我提前写好稿子，对着稿子念。但后面别人提问，我就一脸懵了。</p>
<p>我们从来不意识到会讲英语是多么重要，因为周边没有人讲英语，完全没有讲英语的需求。为了应付考试，也只是学会读和写而已。</p>
<p>等进了外企，或者去国外旅游、生活、工作，才意识到英语的重要性。</p>
<p>当你在国产企业996卷怕了，你会发现外企不卷又轻松，不用加班，年假10天起步，有的还能在家办公。</p>
<p>当你在国内深陷医疗、教育的漩涡，放眼国外，赫然发现别人早实现了全民免费医疗，养孩子还有大量津贴，还不会有动态清零这么荒谬的事情，不禁心向往之。</p>
<p>如果你想要出国留学，先要考过雅思托福才能申请，去了国外也得全英上课。</p>
<p>想要在外企工作，想要移居国外，想要出国留学，英语是必不可少的。</p>
<p>或者你就是纯粹对旅游感兴趣，但是也绝对离不开英语，有中文标识的地方少之又少，必须要会英语，才能畅通出行。</p>
<p>我个人也在学习英语之中，可以分享我用的方法：</p>
<p>1.参加英语课程，我目前在泰国学英语，价格低服务好，不会像国内一样一直跟你推销课程。</p>
<p>2.看英文内容YouTube影片，获得英语，浸润在全英的环境里，提高听力。</p>
<p>3.去国外生活一段时间，你没有机会使用母语，只能使用英语，对于提高英语很有帮助。</p>
<p>4.交一些国外朋友，跟他们讲英文，锻炼口语，让自己习惯去讲英语，克服讲英语的畏惧心理。</p>
    </div>
  }

  





