export const selfDevelopment9 = {
    id: 9,
    title: "个人成长第九弹：选择信仰，让灵性得到成长",
    content: 
    <div>
        <p>人年轻的时候，没有经历过太多苦难，往往不会去思考人生的终极命题。又或者是经历一些事，内心有困惑，但是还没有找到答案，也没意识到这是信仰危机。</p>

        <h2>1.信仰可以在艰难时刻提供心理支撑</h2>
        <p>现在有些年轻人遇到一些问题，被老师骂几句，或者分手了，或者是丢工作了，就选择了纵身一跃。我们不能谴责他们心理脆弱，他们内心一定非常无助，感觉到毫无希望毫无依靠，才会选择自我了断。</p>
        <p>如果他们有自己的信仰，至少有一个可以求助、祈祷的对象，不至于走到最后一步。但这部代表宗教就像安慰剂一样，给你虚假的安慰。网上有大量的证据证明，这个世界并不是唯物的，一定有更高位面的存在，这里不展开讲了。</p>

        <h2>2.信仰可以帮助你生活得更好</h2>
        <p>从佛教的角度讲，学佛的人能消除业障、增长福报，念地藏菩萨、准提咒能帮助你在现实生活中获得更好，会有很多佛菩萨、护法神来帮助你。</p>
        <p>一个基督教的朋友亲口说，她向上帝祈祷之后，找到了更便宜的房子，说明基督教可能也能帮助它的信徒过上更好的生活。</p>

        <h2>3.信仰可以帮你避免危机</h2>
        <p>网上有很多学佛人避免灾难的例子，比如李连杰在印尼海啸的时候躲过了一劫，也有人统计过，没有佛弟子在那次灾难中丧生。还有90年代的广州空难，信佛的人就躲过了一劫。        </p>

        <h2>4.灵性成长可以让你向上提升</h2>
        <p>如果没有灵性的成长，只依靠物质享乐，很容易变得迷茫、抑郁。如果没有足够的物质享乐，人会很痛苦，如果得到满足了，人反而可能会变得更加无所适从，很多有钱人，比如张朝阳，会变得抑郁，也不是没有可能。</p>
        <p>而如果有足够的灵性成长，你能从书本、艺术、宗教中获取精神能量，物质上适量就能知足，这样能获得更快乐、轻松。</p>

        <h2>5.信仰可以让你坦然面对死亡</h2>
        <p>人终有一死，身边的人也会有死去的那一天。</p>
        <p>人有些时候很可笑，以去世的先后来衡量悲伤程度，后去世的人会感到悲伤，仿佛先去世的人没有知觉一样。无论前后，死亡都是苦。</p>
        <p>如果你信佛，深信六道轮回，就能倘然面对这一切了。人生这把游戏可以不断重开，当你修行好了，可以升级，升到天道，甚至成为阿罗汉、菩萨、佛。</p>
        <p>六道轮回现在已经有很多科学证据了，比如海南再生人唐江山，湖南侗乡再生人，《前世今生》这本书。</p>
        <p>当你有了宗教信仰，就不会有烟消云散的虚无感，而是明白人有生生世世轮回，至少你能为此做点什么，可以去修行。</p>
     
    </div>
  }