import React from 'react';
import './SoloProduct.css'
import { NavLink } from 'react-router-dom';

const SoloProduct = () => {
  return (
    <section className='productList'>
      <h1>My Solo Product:</h1>
      <div>
      <div>
        <h1>在綫念佛計數器：</h1>
        <div className='web-counter'>
        <NavLink to='/buddhism/diZangPuSa'>地藏王菩薩</NavLink>
        <NavLink to='/buddhism/guanyinpusa'>觀世音菩薩</NavLink>
        <NavLink to='/buddhism/amituofo'>阿彌陀佛</NavLink>
        <NavLink to='/buddhism/yaoshifo'>藥師佛</NavLink>
        <NavLink to='/buddhism/zhuntizhou'>準提咒</NavLink>
        </div>
        
      </div>

      <div>
        <h1>Google play應用：</h1>
        <div className='google-play'>
          <a
                href="https://play.google.com/store/apps/details?id=com.wws.dizang"
                target="_blank" 
                rel="noopener noreferrer"
                >
                  地藏王菩薩
          </a>

          <a
                href="https://play.google.com/store/apps/details?id=com.wws.guanyinpusa"
                target="_blank" 
                rel="noopener noreferrer"
                >
                  觀世音菩薩
          </a>

          <a
                href="https://play.google.com/store/apps/details?id=com.wws.amituofo"
                target="_blank" 
                rel="noopener noreferrer"
                >
                  阿彌陀佛
          </a>

          <a
                href="https://play.google.com/store/apps/details?id=com.wws.yaoshifo"
                target="_blank" 
                rel="noopener noreferrer"
                >
                  藥師佛
          </a>

          <a
              href="https://play.google.com/store/apps/details?id=com.wws.zhuntizhou"
              target="_blank" 
              rel="noopener noreferrer"
              >
                準提咒
        </a>
        </div>
        

        
      </div>

      <div>
        <h1>other:</h1>
        <NavLink to='/solo-product/language-detector'>Language detector</NavLink>
      </div>
      </div>
    </section>
  );
};

export default SoloProduct;
