const languageNamesCn = {
  aar: '阿法尔语',
  abk: '阿布哈兹语',
  afr: '南非荷兰语',
  aka: '阿坎语',
  amh: '阿姆哈拉语',
  ara: '阿拉伯语',
  asm: '阿萨姆语',
  aym: '艾马拉语',
  aze: '阿塞拜疆语',
  bak: '巴什基尔语',
  bam: '班巴拉语',
  bel: '白俄罗斯语',
  ben: '孟加拉语',
  bis: '比斯拉马语',
  bod: '藏语',
  bos: '波斯尼亚语',
  bre: '布列塔尼语',
  bul: '保加利亚语',
  cat: '加泰罗尼亚语',
  ces: '捷克语',
  cha: '查莫罗语',
  che: '车臣语',
  chu: '古教会斯拉夫语',
  chv: '楚瓦什语',
  cor: '康沃尔语',
  cos: '科西嘉语',
  cre: '克里语',
  cym: '威尔士语',
  dan: '丹麦语',
  deu: '德语',
  div: '迪维希语',
  dzo: '宗卡语',
  ell: '现代希腊语',
  eng: '英语',
  epo: '世界语',
  est: '爱沙尼亚语',
  ewe: '埃维语',
  fao: '法罗语',
  fas: '波斯语',
  fij: '斐济语',
  fin: '芬兰语',
  fra: '法语',
  fry: '西弗里西亚语',
  ful: '富拉语',
  gla: '盖尔语',
  gle: '爱尔兰语',
  glg: '加利西亚语',
  glv: '马恩岛语',
  grn: '瓜拉尼语',
  guj: '古吉拉特语',
  hau: '豪萨语',
  heb: '希伯来语',
  her: '赫雷罗语',
  hin: '印地语',
  hmo: '希里莫图语',
  hrv: '克罗地亚语',
  hun: '匈牙利语',
  ibo: '伊博语',
  ind: '印尼语',
  isl: '冰岛语',
  ita: '意大利语',
  jav: '爪哇语',
  jpn: '日语',
  kal: '格陵兰语',
  kan: '卡纳达语',
  kas: '克什米尔语',
  kat: '格鲁吉亚语',
  kau: '卡努里语',
  kaz: '哈萨克语',
  khm: '高棉语',
  kik: '基库尤语',
  kin: '卢旺达语',
  kir: '柯尔克孜语',
  kom: '科米语',
  kon: '刚果语',
  kor: '韩语',
  kua: '宽亚玛语',
  kur: '库尔德语',
  lao: '老挝语',
  lat: '拉丁语',
  lav: '拉脱维亚语',
  lin: '林加拉语',
  lit: '立陶宛语',
  ltz: '卢森堡语',
  lub: '卢巴加丹加语',
  lug: '卢干达语',
  mah: '马绍尔语',
  mal: '马拉雅拉姆语',
  mar: '马拉地语',
  mkd: '马其顿语',
  mlg: '马达加斯加语',
  mlt: '马耳他语',
  mon: '蒙古语',
  mri: '毛利语',
  msa: '马来语',
  mya: '缅甸语',
  nau: '瑙鲁语',
  nav: '纳瓦霍语',
  nbl: '南恩德贝勒语',
  nde: '北恩德贝勒语',
  ndo: '恩东加语',
  nep: '尼泊尔语',
  nld: '荷兰语',
  nno: '挪威尼诺斯克语',
  nob: '挪威博克马尔语',
  nor: '挪威语',
  nya: '齐切瓦语',
  oci: '奥克西唐语',
  oji: '奥吉布瓦语',
  ori: '奥里亚语',
  orm: '奥罗莫语',
  oss: '奥塞梯语',
  pan: '旁遮普语',
  pli: '巴利语',
  pol: '波兰语',
  por: '葡萄牙语',
  pus: '普什图语',
  que: '克丘亚语',
  roh: '罗曼什语',
  ron: '罗马尼亚语',
  run: '基隆迪语',
  rus: '俄语',
  sag: '桑戈语',
  san: '梵语',
  sin: '僧伽罗语',
  slk: '斯洛伐克语',
  slv: '斯洛文尼亚语',
  sme: '北萨米语',
  smo: '萨摩亚语',
  sna: '绍纳语',
  snd: '信德语',
  som: '索马里语',
  sot: '南索托语',
  spa: '西班牙语',
  srd: '撒丁语',
  srp: '塞尔维亚语',
  ssw: '斯威士兰语',
  sun: '巽他语',
  swa: '斯瓦希里语',
  swe: '瑞典语',
  tah: '塔希提语',
  tam: '泰米尔语',
  tat: '鞑靼语',
  tel: '泰卢固语',
  tet: '泰顿语',
  tgk: '塔吉克语',
  tgl: '塔加洛语',
  tha: '泰语',
  tir: '提格里尼亚语',
  ton: '汤加语',
  tsn: '茨瓦纳语',
  tso: '聪加语',
  tuk: '土库曼语',
  tur: '土耳其语',
  twi: '契维语',
  uig: '维吾尔语',
  ukr: '乌克兰语',
  urd: '乌尔都语',
  uzb: '乌兹别克语',
  ven: '文达语',
  vie: '越南语',
  vol: '沃拉普克语',
  wln: '瓦隆语',
  wol: '沃洛夫语',
  xho: '科萨语',
  yid: '意第绪语',
  yor: '约鲁巴语',
  zha: '壮语',
  zho: '中文',
  zul: '祖鲁语',
  cmn: '中文'
};

export default languageNamesCn